import React from "react"

import Img from "gatsby-image"

const HeroImage = ({ className, image }) => {
  const imageData = image.fluid
  const alt = image.title
  return (
    <Img
      fluid={imageData}
      alt={alt}
      className={className}
      style={{
        position: "absolute",
      }}
    />
  )
}

export default HeroImage
