/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

import "../styles/main.scss"

const Layout = ({ children, pagePath }) => (
  <StaticQuery
    query={graphql`
      query Layout {
        contentfulSiteConfiguration(title: { ne: "ignore" }) {
          title
          configMenu {
            title
            url
          }
          configEmail
          configPhoneNumber
          configFacebook
          configInstagram
          configFooterContent {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Header
          menu={data.contentfulSiteConfiguration.configMenu}
          pagePath={pagePath}
          title={data.contentfulSiteConfiguration.title}
        />
        <main className="main" role="document">
          {children}
        </main>
        <Footer
          title={data.contentfulSiteConfiguration.title}
          email={data.contentfulSiteConfiguration.configEmail}
          phone={data.contentfulSiteConfiguration.configPhoneNumber}
          facebook={data.contentfulSiteConfiguration.configFacebook}
          instagram={data.contentfulSiteConfiguration.configInstagram}
          content={data.contentfulSiteConfiguration.configFooterContent}
        />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pagePath: PropTypes.node.isRequired,
}

export default Layout
