import React from "react"
import { Link as AnchorLink } from "react-scroll"
import { Link } from "gatsby"

import Logotype from "../images/logotype.svg"

class Header extends React.Component {
  componentDidMount() {
    var scrollpos = window.scrollY
    var header = document.querySelector("header")

    function add_class_on_scroll() {
      header.classList.add("header--background")
    }

    function remove_class_on_scroll() {
      header.classList.remove("header--background")
    }

    window.addEventListener("scroll", function() {
      scrollpos = window.scrollY

      if (scrollpos > 10) {
        add_class_on_scroll()
      } else {
        remove_class_on_scroll()
      }
    })
  }
  render() {
    const pagePath = this.props.pagePath
    const title = this.props.title
    const menu = this.props.menu
    return (
      <header className="header">
        <div className="header__container u-wrap u-wrap--large">
          {pagePath === "/" ? (
            <nav className="nav js-nav">
              <div className="nav__container">
                <ul id="menu-menu" className="nav__content">
                  {menu.map((item, index) => {
                    return (
                      <li key={index} className="nav__item">
                        <AnchorLink
                          to={item.url}
                          className="nav__link"
                          activeClass="nav__link--active"
                          spy={true}
                          smooth={true}
                          hashSpy={false}
                        >
                          {item.title}
                        </AnchorLink>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </nav>
          ) : (
            <Link to="/" className="logo logo--header">
              <img className="logo__image" src={Logotype} alt={title} />
            </Link>
          )}
        </div>
      </header>
    )
  }
}

export default ({ menu, pagePath, title }) => (
  <Header menu={menu} pagePath={pagePath} title={title} />
)
