import React from "react"

import Logotype from "../images/logotype.svg"
import HeroImage from "./heroImage"

const Hero = ({ title, image, pagePath }) => {
  function Hero(props) {
    const pagePath = props.pagePath
    const title = props.title
    const image = props.image
    if (pagePath === "/") {
      return (
        <div className="hero">
          {image && (
            <>
              <HeroImage className="hero__image-wrapper" image={image} />
              <div className="hero__overlay" />
            </>
          )}
          <div className="hero__content u-wrap u-wrap--large">
            <h1 className="logo logo--hero">
              <img className="logo__image" src={Logotype} alt={title} />
            </h1>
            <img
              className="hero__sigill"
              alt="Vinnare av Gulddraken 2020"
              src="https://images.ctfassets.net/lbd6k9tn3mpx/6a847XtdLCx9c3U4UfO7yq/35090ae339538dcd5cb434b2b79cba95/sigill_webb.png"
            />
          </div>
        </div>
      )
    }
    return (
      <div className="hero hero--on-page">
        {image && (
          <>
            <HeroImage className="hero__image-wrapper" image={image} />
            <div className="hero__overlay" />
          </>
        )}
        <div className="hero__content u-wrap u-wrap--large">
          <h1 className="hero__heading heading">{title}</h1>
        </div>
      </div>
    )
  }

  return <Hero title={title} image={image} pagePath={pagePath} />
}

export default Hero
